import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import './../assets/slick/slick.scss';
import './../assets/slick/slick-theme.scss';
import { useMatchMediaContext } from 'sana/utils';
import ImageWrapper from './imageWrapper/ImageWrapper';
import {
  structureDotNavigatorSettings,
  structureDotNavigatorIconSettings,
  structureAutoPlaySettings,
  structureNavigationButtonsSettings,
  structureMiscellaneousSettings,
  setEmptyImageSettings,
  responsiveSettings,
} from './../utils/';

const RenderSlider = ({ modelData }) => {
  const { xs: isMobile } = useMatchMediaContext();
  const [activeDotIndex, setActiveDotIndex] = useState(0);
  const {
    dotNavigator,
    navigationButtons,
    autoPlay,
    images,
    miscellaneous,
  } = modelData;
  const { isIncludeInside, iconHeight } = dotNavigator;

  const dotNavigatorSettings = structureDotNavigatorSettings(dotNavigator);
  const dotNavigatorIconSettings = structureDotNavigatorIconSettings(
    dotNavigator,
    activeDotIndex,
  );
  const autoPlaySettings = structureAutoPlaySettings(autoPlay);
  const navigationButtonsSettings = structureNavigationButtonsSettings(
    navigationButtons,
  );
  const {
    miscellaneousSettings,
    spaceBetweenImages,
  } = structureMiscellaneousSettings(miscellaneous);
  const processedImages = setEmptyImageSettings(images);

  return (
    <div style={{ paddingBottom: !isIncludeInside && `${32 + iconHeight}px` }}>
      <Slider
        {...dotNavigatorSettings}
        {...dotNavigatorIconSettings}
        {...navigationButtonsSettings}
        {...autoPlaySettings}
        {...miscellaneousSettings}
        {...responsiveSettings}
        beforeChange={(prev, next) => setActiveDotIndex(next)}
      >
        {processedImages.map(image => (
          <ImageWrapper
            {...image}
            spaceBetweenImages={spaceBetweenImages}
            key={image.imagePath}
            isMobile={isMobile}
            imageScalingOption={miscellaneous.imageScalingOption}
          />
        ))}
      </Slider>
    </div>
  );
};

RenderSlider.propTypes = {
  modelData: PropTypes.shape({
    dotNavigator: PropTypes.shape({
      isDotNavigatorVisible: PropTypes.bool.isRequired,
      navigatorIcon: PropTypes.string,
      navigatorIconOnHover: PropTypes.string,
      iconHeight: PropTypes.number,
      isIncludeInside: PropTypes.bool,
    }),
    autoPlay: PropTypes.shape({
      isAutoPlay: PropTypes.bool.isRequired,
      rotationDelay: PropTypes.number,
    }),
    navigationButtons: PropTypes.shape({
      showNavigation: PropTypes.bool.isRequired,
      previousIcon: PropTypes.string,
      nextIcon: PropTypes.string,
    }),
    miscellaneous: PropTypes.shape({
      transformAs: PropTypes.number.isRequired,
      numbersOfImages: PropTypes.number,
      spaceBetweenImages: PropTypes.number,
    }),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        titleText: PropTypes.string,
        titleTextColor: PropTypes.string,
        subtitleText: PropTypes.string,
        subtitleTextColor: PropTypes.string,
        buttonText: PropTypes.string,
        buttonTextColor: PropTypes.string,
        buttonLink: PropTypes.object,
        imagePath: PropTypes.string,
        imageAlterText: PropTypes.string,
        buttonBottomBorderColor: PropTypes.string,
        buttonBackgroundHoverColor: PropTypes.string,
        buttonBottomBorderHoverColor: PropTypes.string,
        buttonBackgroundColor: PropTypes.string,
        buttonPosition: PropTypes.number,
      }),
    ),
  }),
};

export default RenderSlider;
